<template>
  <div class="announcement">
    <el-dialog :visible.sync="dialogVisible" width="800px" :show-close="false">
      <div class="announcement-box">
        <div class="announcement-title">消息通知</div>
        <div class="announcement-list">
          <div class="announcement-item" v-for="item in announcementList" :key="item.id">
            <div class="announcement-item_nav">
              <div class="announcement-item-title">{{ item.title }}</div>
              <!-- <div class="announcement-item-time">{{ item.create_time }}</div> -->
            </div>
            <div class="announcement-item-content">{{ item.content_part }}</div>
            <div class="announcement-item-buttom">
              <div class="announcement-item-data">
                {{item.create_time}}
              </div>
              <div class="announcement-item-ckxq" @click="goPage(item.id)">查看详情</div>
            </div>
            
            
          </div>
        </div>
        <div class="pagination">
          <div>共 <span style="color:#22AC35;">{{ announcementPageObj.total }}</span> 条数据</div>
          <el-pagination :small="true" @size-change="announcementSizeChange" @current-change="announcementCurrentChange"
          :current-page.sync="announcementPageObj.current" :page-size="announcementPageObj.size"
          layout="prev, pager, next, jumper" :total="announcementPageObj.total"></el-pagination>
        </div>
        
      </div>
    </el-dialog>
  </div>
</template>

<script>
import router from '@/router';
import { getNoticeListApi } from '@/api/system.js'
export default {
  name: 'announcement',
  data() {
    return {
      dialogVisible: false,
      announcementList: [],
      announcementPageObj: {
        current: 1, // 当前页
        size: 10, // 每页的数量
        total: 0,
      },
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.announcementPageObj.current = 1
        this.getNoticeList()
      }
    },
  },
  mounted() {
    this.getNoticeList()
  },
  methods: {
    goPage(id) {
      router.push({
        name: 'inform',
        query: { id },
      })
      this.dialogVisible = false
    },
    getNoticeList() {
      let params = {
        current: this.announcementPageObj.current,
        size: this.announcementPageObj.size,
      }
      getNoticeListApi(params)
        .then((res) => {
          let data = res.data
          this.announcementPageObj.total = data.total
          this.announcementList = data.records
        })
        .catch((err) => { })
    },
    announcementSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    // 切换分页
    announcementCurrentChange(val) {
      this.announcementPageObj.current = val
      this.getNoticeList()
    },
  },
}
</script>

<style lang="scss">
.el-dialog {
  margin-top: 50px !important;
}

.announcement {
  width: 100%;
  height: 100%;

  .announcement-box {
    display: flex;
    flex-direction: column;
    height: 600px;
    padding: 24px;
    overflow-y: auto;
    scrollbar-color: rgba(0, 0, 0, 0.1) rgba(251, 246, 246, 0.1); //滚动条轨道颜色   滚动条滑块的颜色
    scrollbar-width: none;
    scrollbar-width: thin; //thin模式下滚动条两端的三角按钮会消失
    box-sizing: border-box;

    .announcement-title {
      font-size: 18px;
      color: #000;
      font-weight: 700;
    }

    .announcement-list {
      margin-top: 24px;
      height: 100%;
      overflow-y: auto;
      scrollbar-color: rgba(0, 0, 0, 0.1) rgba(251, 246, 246, 0.1); //滚动条轨道颜色   滚动条滑块的颜色
      scrollbar-width: none;
      // scrollbar-width: thin; //thin模式下滚动条两端的三角按钮会消失
      color: #2d303b;
      border-top: 1px solid #DDDFE7;

      .announcement-item {
        padding-top: 24px;
        

        .announcement-item_nav {
          display: flex;
          align-items: center;
          // margin-bottom: 20px;

          .announcement-item-title {
            margin-right: 30px;
            font-size: 16px;
            color: #303133;
            font-weight: 700;
            white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          }
        }
        .announcement-item-content{
          padding: 12px 0;
          color: #606266;
          font-size: 14px;
        }
        .announcement-item-buttom{
          display:flex;
          justify-content: space-between;
          align-items: center;
          .announcement-item-data{
            color: #909399;
font-size: 12px;
font-style: normal;
font-weight: 400;
          }
          .announcement-item-ckxq {
            color: #22AC35;
          // padding: 10px 0 20px;
          font-size: 12px;
          cursor: pointer;
        }
        }
        
      }
    }
    .pagination{
      display:flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 24px;
          margin-top: 24px;
    }
  }
}
</style>