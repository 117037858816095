import {
  setStorage,
  getStorage
} from "@/utils/storage.js"

const mqtt = {
  state: {
    mqttKey: getStorage({ name: 'mqttKey' }) || '',
    currMqttMsg: {},//当前接收的信息
    reconMqttTime: '', //mqtt重连时间
  },
  actions: {

  },
  mutations: {
    SET_MQTT_KEY(state, mqttKey) {
      state.mqttKey = mqttKey
      setStorage({
        name: 'mqttKey',
        content: state.mqttKey
      })
    },
    SET_CURR_MQTT_MSG(state, currMqttMsg) {
      state.currMqttMsg = currMqttMsg
    },
    SET_RECON_MQTT_TIME(state, reconMqttTime) {
      state.reconMqttTime = reconMqttTime
    },
  }
}

export default mqtt