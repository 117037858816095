/**
 * 全局配置文件
 */
import { env } from './env';
export default {
    /*  mqtt链接配置
    */
    mqttMy: env.NODE_ENV === 'development' ? 'ws://192.168.5.94:5883/mqtt' : 'ws://',

    //微信pc扫码登录
    redirect_url: env.NODE_ENV === 'development' ? 'http://test-aiserver.chuhai-bang.com' : 'https://gotoseaai.com',
    // redirect_url:'https://gotoseaai.com',
    // 微信登录id
    wxAppid: 'wxd0e88921d57e458a',
    key: 'uni-chatgpt-web',//配置主键,目前用于存储
    tokenHeader: 'Blade-Auth',
    //接口访问服务头部
    Authorization: 'c2FiZXI6c2FiZXJfc2VjcmV0',
    // apiRequestHead: env.NODE_ENV == 'development' ? 'ai-mjkj-chat' : 'ai-mjkj-chat',
    apiRequestHead: 'ai-mjkj-chat',
    mqttUserName: 'MJKJ_CHATGPT', // rabbitmq连接用户名
    mqttPassword: 'MJKJ_CHATGPT@QWER', // rabbitmq连接密码
}